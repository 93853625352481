<template>
	<div
		:id="`signature-page-${pageNumber}`"
		class="canvas-wrapper"
		:data-real-height="realHeight"
		:data-real-width="realWidth"
		v-bind="canvasAttrs"
		@click="onClick($event)"
		@dragover="onDragOver($event)"
		@drop="onDrop($event)"
	>
		<canvas v-visible.once="drawPage" v-bind="canvasAttrs"> </canvas>
		<SignatureArea v-for="signatureArea in signatureAreas" :key="signatureArea.id" :value="signatureArea" />
	</div>
</template>

<script>
import SignatureModuleGuard from '@/mixins/ModulesGuards/Signature/SignatureModuleGuard'
import PDFPreview from '@/helpers/PDFPreview'
import Vue from 'vue'
import AddSignatoryComponent from '@/components/Documents/Signature/Preview/AddSignatory'

export default {
	name: 'PDFPage',
	components: {
		SignatureArea: () => ({
			component: import('@/components/Documents/Signature/Preview/SignatureArea')
		})
	},
	directives: {
		visible: PDFPreview.directives.visible
	},
	mixins: [SignatureModuleGuard],
	props: {
		page: {
			required: true,
			type: Object
		},
		scale: {
			required: true,
			type: Number
		},
		optimalScale: {
			required: false,
			type: Number,
			default: 1
		},
		isPageFocused: {
			required: false,
			type: Boolean,
			default: false
		},
		isElementFocused: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			oldPageSize: {},
			pixelRatio: 1,
			DEFAULT_AREA_WIDTH: 200,
			DEFAULT_AREA_HEIGHT: 61
		}
	},
	computed: {
		actualSizeViewport: function () {
			return this.viewport.clone({ scale: this.scale })
		},
		canvasStyle: function () {
			const { width: actualSizeWidth, height: actualSizeHeight } = this.actualSizeViewport
			const [pixelWidth, pixelHeight] = [actualSizeWidth, actualSizeHeight].map(dim => Math.ceil(dim / this.pixelRatio))
			return `width:${pixelWidth}px;height:${pixelHeight}px`
		},
		canvasAttrs: function () {
			let { width, height } = this.viewport
			;[width, height] = [width, height].map(dim => Math.ceil(dim))
			const style = this.canvasStyle
			return {
				width,
				height,
				style,
				class: 'pdf-page box-shadow'
			}
		},
		pageNumber: function () {
			return this.page.pageNumber
		},
		realHeight: function () {
			return this.getPDFRealDimensions().height
		},
		realWidth: function () {
			return this.getPDFRealDimensions().width
		},
		signatureAreas: function () {
			try {
				return this.getSignatureAreas(this.pageNumber)
			} catch (e) {
				if (e.name == 'UnknownPageException' && !this._isMounted) {
					return []
				}
				throw e
			}
		},
		viewport: function () {
			return this.page.getViewport({ scale: 2.9 * this.optimalScale })
		}
	},
	watch: {
		scale: function () {
			this.updateVisibility()
		},
		page: function (_newPage, oldPage) {
			this.destroyPage(oldPage)
		},
		isElementFocused: function (isElementFocused) {
			if (isElementFocused) {
				this.focusPage()
			}
		}
	},
	created: function () {
		this.pixelRatio = PDFPreview.constants.PIXEL_RATIO
	},
	destroyed: function () {
		window.removeEventListener('resize', this.handleResize)
	},
	mounted: function () {
		window.addEventListener('resize', this.handleResize)
	},
	methods: {
		isCanvasTransparent () {
			const canvas = this.$el
			const canvasContext = canvas.getContext('2d')
			const imageData=canvasContext.getImageData(0, 0, canvas.offsetWidth, canvas.offsetHeight);
			for (let i=0; i<imageData.data.length; i+=4) {
				if (imageData.data[i+3] !== 0) {
					return false
				}
			}
			return true
		},
		destroyPage (page) {
			if (page) {
				page._destroy()
			}
			this.destroyRenderTask()
		},
		destroyRenderTask () {
			if (!this.renderTask) {
				return
			}
			this.renderTask.cancel()
			delete this.renderTask
		},
		drawPage () {
			if (this.renderTask) {
				return
			}
			const { viewport } = this
			const canvasContext = this.$el.firstChild.getContext('2d')
			const renderContext = { canvasContext, viewport }
			this.renderTask = this.page.render(renderContext)
			this.renderTask.promise
				.then(() => {
					let isTransparent = this.isCanvasTransparent()
					if (isTransparent) {
						this.renderTask = null
						this.drawPage()
					} else {
							this.$emit('page-rendered', {
								page: this.page,
								text: `Rendered page ${this.pageNumber}`
						})
					}
				})
				.catch(response => {
					this.destroyRenderTask()
					this.$emit('page-errored', {
						response,
						page: this.page,
						text: `Failed to render page ${this.pageNumber}`
					})
				})
		},
		focusPage () {
			if (this.isPageFocused) {
				return
			}
			this.$emit('page-focus', this.pageNumber)
		},
		getPDFRealDimensions: function () {
			const view = this.page.view

			let height, width

			if (this.page.rotate == 90 || this.page.rotate == 270) {
				height = view[2] - view[0]
				width = view[3] - view[1]
			} else {
				height = view[3] - view[1]
				width = view[2] - view[0]
			}

			return {
				height,
				width
			}
		},
		handleResize: function () {
			this.pixelRatio = PDFPreview.constants.PIXEL_RATIO
		},
		onClick: function (event) {
			const coords = {
				x: event.clientX,
				y: event.clientY
			}
			this.drawSignatureArea(this, {
				coords,
				page: this.pageNumber
			})
		},
		onDragOver: function (event) {
			event.preventDefault()
		},
		onDrop: function (event) {
			event.preventDefault()

			const signatureAreaId = event.dataTransfer.getData('id')
			const offsetX = parseInt(event.dataTransfer.getData('offsetX'))
			const offsetY = parseInt(event.dataTransfer.getData('offsetY'))

			const coords = {
				x: event.x - offsetX,
				y: event.y - offsetY
			}

			this.drawSignatureArea(
				this,
				{
					coords,
					page: this.pageNumber
				},
				signatureAreaId
			)
		},
		updateVisibility () {
			this.$parent.$emit('update-visibility')
		},
		getSignatory: function (context, signatureAreaId = null) {
			let signatory = {}
			if (signatureAreaId) {
				const signatureArea = this.findSignatureArea(signatureAreaId)
				signatory = signatureArea.signatory
			}
			return new Promise((res, rej) => {
				const componentInstance = Vue.extend(AddSignatoryComponent)
				const component = new componentInstance({
					propsData: {
						value: signatory
					},
					parent: context
				}).$mount()
				document.getElementById('app').appendChild(component.$el)
				component.$on('close', () => {
					document.getElementById('app').removeChild(component.$el)
					component.$destroy()
					rej('Cancelled')
				})
				component.$on('input', event => {
					document.getElementById('app').removeChild(component.$el)
					component.$destroy()
					res(event)
				})
			})
		}
	}
}
</script>

<style>
.canvas-wrapper {
	cursor: crosshair;
	margin: 0 auto;
	position: relative;
}

.pdf-page {
	display: block;
	position: relative;
}
</style>
