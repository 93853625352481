<template>
	<w-dialog
		max-width="500px"
		:title="isUpdating ? $t('signature.documents.update_signatory') : $t('signature.documents.add_signatory')"
		:value="dialog"
		@close="closeDialog"
	>
		<w-layout column>
			<w-text-info :text="infoMessageSignatories" />
			<v-form v-model="isValid">
				<w-email-input
					v-model.trim="signatory.email"
					autofocus
					:label="$t('signature.documents.fields.email')"
					:loading="loading"
					required
					@blur="onBlur()"
					@keypress="onKeyPress()"
				/>
				<w-layout row>
					<w-text-input
						v-model.trim="signatory.firstname"
						:disabled="signatoryExists && originalUser && originalUser.firstname"
						class="mr-1"
						:label="$t('signature.documents.fields.firstname')"
						required
					/>
					<w-text-input
						v-model.trim="signatory.lastname"
						:disabled="signatoryExists && originalUser && originalUser.lastname"
						class="ml-1"
						:label="$t('signature.documents.fields.lastname')"
						required
					/>
				</w-layout>
				<w-phone-input
					v-model.trim="signatory.phone"
					:disabled="signatoryExists && originalUser && originalUser.mobile_number"
					:label="$t('signature.documents.fields.phone_number')"
					required
					@keypress.enter="emitInput()"
				/>
			</v-form>
		</w-layout>
		<template v-slot:actions>
			<w-spacer />
			<w-btn :disabled="!isValid && !loading" flat @click="emitInput()">{{ $t('actions.validate') }}</w-btn>
		</template>
	</w-dialog>
</template>

<script>
import SignatureModuleGuard from '@/mixins/ModulesGuards/Signature/SignatureModuleGuard'
import Validator from '@/mixins/Validator'

export default {
	name: 'AddSignatory',
	mixins: [SignatureModuleGuard, Validator],
	props: {
		value: {
			required: false,
			type: Object,
			default: () => ({})
		}
	},
	data: function () {
		return {
			dialog: false,
			loading: false,
			isValid: false,
			signatory: {},
			signatoryExists: false,
			originalUser: {}
		}
	},
	computed: {
		isUpdating: function () {
			return this.value.email
		},
		numberSignatories: function () {
			return this.getSignatureAreas().length
		},
		isMoreThanOneSignatory: function () {
			return (this.numberSignatories > 1 && this.isUpdating) || (!this.isUpdating && this.numberSignatories > 0)
		},
		infoMessageSignatories: function () {
			let message = this.$t('signature.documents.add_signatory_information')
			return this.isMoreThanOneSignatory ? message + ' ' + this.$t('signature.documents.add_several_signatories_information') : message
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (val) {
				if (val) {
					Object.assign(this.signatory, val)
				}
			},
			immediate: true
		}
	},
	mounted: function () {
		this.dialog = true
	},
	methods: {
		checkIfSignatoryExists: function () {
			this.loading = true
			const signatureAreas = this.service.getSignatureAreas()
			let maxOrder = 0
			for (const { signatory } of signatureAreas) {
				if (maxOrder < signatory.order) {
					maxOrder = signatory.order
				}
				if (signatory.email === this.signatory.email) {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('sign.error.signatory_already_exists'))
					return Promise.resolve({
						id: null,
						firstname: '',
						lastname: '',
						order: maxOrder + 1,
						phone: '',
						email: '',
						error: this.$t('sign.error.signatory_already_exists')
					})
				}
			}
			this.service
				.getSignatory(this.signatory.email, { show_error: false })
				.then(user => {
					Object.assign(this.originalUser, user)
					this.signatoryExists = !!user?.email
					if (user?.error) {
						this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, user.error)
					}
					user.phone = user.mobile_number
					user.order = maxOrder + 1
					Object.assign(this.signatory, user)
				})
				.catch(() => {
					this.signatory.order = maxOrder + 1
				})
				.finally(() => {
					this.loading = false
				})
		},
		closeDialog: function () {
			this.$emit('close')
		},
		emitInput: function () {
			if (!this.isValid || this.loading) {
				return
			}
			this.formatPhoneNumber()
			this.$emit('input', this.signatory)
		},
		onBlur: function () {
			if (this.signatory.email && this.validateEmail(this.signatory.email)) {
				this.checkIfSignatoryExists(this.signatory.email)
			}
		},
		onKeyPress: function () {
			this.signatoryExists = false
		},
		/**
		 * Remove text characters and keep only digits and + character
		 * @return void
		 */
		formatPhoneNumber () {
			this.signatory.phone = this.signatory.phone.replace(new RegExp('[^\\d^+]', 'g'), '')
		}
	}
}
</script>
